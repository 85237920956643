<template>
 <!-- . . . . . . . . . . . . . . . . . . .  . . . .  --> 
 <!-- . . . . Navigation bar . . . . . . .   . . . .  -->
 <!-- . . . . . . . . . . . . . . . . . . .  . . . .  --> 
<NavbarComp :isMob="isMob" @changeToOne="changeOne" 
:str1="slate.f" :str2="slate.w"  />
<!-- . . . . . . . . . . . . . . . . . . .  . . . .  -->
<!-- . . . . . . . . . . . . . . . . . . .  . . . .  --> 
<!-- . . . . . . . . . . . . . . . . . . .  . . . .  --> 

<div class="container-fluid  mt-4">  
<div class="container bg-white col-md-12 ">
<!-- . . . . . . . . . . . . . . . . . . .  . . . .  -->
<!-- . . . .Show Message Component. . . . . .  . . . --> 
<!-- . . . . . . . . . . . . . . . . . . .  . . . .  -->
<MessageComp :msg="slate.d" :lnk="slate.e" :per="slate.h" @focusOnSome="focusOnTable"/>
<!-- . . . . . . . . . . . . . . . . . . .  . . . .  --> 
<!-- . . . Navigation Element - Left Right. . . .  . -->
<!-- . . . . . . . . . . . . . . . . . . .  . . . .  -->
 <div class="container d-flex justify-content-center">
  <table border="0" class="table-primary"><tr><td> <button class="btn sideblock" @click="moveItt('left')">&#8882;</button></td>
  <span v-if="actOne">
  <span v-for="item in mybase" :key="item.idd">
    <td v-if="item.visible==true"><button class="btn retblock" @click="showItt(item)">{{ item.name }}</button></td>
   </span>
   </span>
   <td><button class="btn sideblock" @click="moveItt('right')">&#8883;</button></td>
  </tr></table>
</div> 
   
<!-- . . . . . . . . . . . . . . . . . . .  . . . .  -->   
<!-- . .Show content after onclick button  . . . .  -->
<!-- . . . . . . . . . . . . . . . . . . .  . . . .  -->
  <br>
  <div v-if="isActive" class="container d-flex col-md-8">
  <table border="1" class="table-primary"><tr><td>
  <span class="d-flex justify-content-start" border="1"><strong>#{{itt.idd}}</strong></span>
    <strong>{{slate.a}}</strong>:  {{itt.name}}<br>
   <strong>{{slate.b}}</strong>:   {{itt.description}}<br>
    <strong>{{slate.c}}</strong>:  {{itt.development}} <br>
     <span v-if="itt.photos!='nullable'">
        <strong>{{slate.i}}</strong>:
        <span v-for="ity in itt.photos" :key="ity.kk">
        <span class="col-sm"><!-- . .Images List.  -->
        <a id="myImg" alt="Snow" @click="showMod(ity.vv)">
        <img :src="ity.vv" width="80" height="80"></a>&nbsp;
        </span> </span><br>
    </span>
       <span v-if="itt.link!='nullable'">
          <strong>{{slate.j}}</strong>:
          <a :href="itt.link" target="_blank"><button class='btn btn-sm btn-outline-primary mt-2'>{{slate.m}}</button></a>
       </span>
    
    
    </td></tr></table>
  </div>
  <!-- . . . . . . . . . . . . . . . . . . .  . . . .  -->
  <!-- . show picture modal. . . . . . . . . . . . . . -->
  <!-- . . . . . . . . . . . . . . . . . . .  . . . .  --> 
  <DModalComp :title="slate.k" modalname="viewImage" :isMob="isMob" :mimage="simage"  />
  <!-- . . . . . . . . . . . . . . . . . . .  . . . .  --> 
   <!-- . show about modal. . . . . . . . . . . . . . -->
   <!-- . . . . . . . . . . . . . . . . . . .  . . . .  --> 
  <DModalComp title="About" modalname="viewAbout"   isMob="nothing" :slate="slate">
  <template v-slot:content>
        <AboutComp :slate="slate" />    
  </template>
  </DModalComp>
  <!-- . . . . . . . . . . . . . . . . . . .  . . . .  --> 
  <br>
<!-- . . . . . . . . . . . . . . . . . . .  . . . .  -->  
<!-- . . .Table - All content. . . . .  . . . . . .  -->
<!-- . . . . . . . . . . . . . . . . . . .  . . . .  -->
<div id="tabela"></div><div id="table"></div>
<input type="hidden" ref="refhidden">
 <TableComp :lang="slate" :realpro="realPro" /><br>
<!-- . . . . . . . . . . . . . . . . . . .  . . . .  --> 
</div>  
</div> 
<!-- . . . . . . . . . . . . . . . . . . .  . . . .  -->   
<!-- . . . . . . . . . . . . . . . . . . .  . . . .  --> 
<!-- . . . . . . . . . . . . . . . . . . .  . . . .  --> 
<!-- . . . . . . . . . . . . . . . . . . .  . . . .  --> 
<!-- . . . . . . . . . . . . . . . . . . .  . . . .  --> 
<!-- . .Rodapé  Footer Component . . . . .  . . . .  --> 
<br><br>
<FooterComp />
<!-- . . . . . . . . . . . . . . . . . . .  . . . .  --> 
<!-- . . . . . . . . . . . . . . . . . . .  . . . .  --> 
<!-- . . . . . . . . . . . . . . . . . . .  . . . .  --> 
</template>
<script>
 /* eslint-disable */
import prj from '@/jsons/projs.json';

import MessageComp from '@/components/Message.vue';
import DModalComp from '@/components/DModal.vue';
import TableComp from '@/components/Table.vue';
import FooterComp from '@/components/Footer.vue';
import AboutComp from '@/components/About.vue';
import NavbarComp from '@/components/Navbar.vue';
import configvar from '@/jsons/confs.json';

  export default {
 
   name: 'FolioComp',
 //  props: { items: {} },
    components: {
        MessageComp,DModalComp,TableComp,FooterComp,AboutComp,NavbarComp
    },
    data(){
        return {
            itt:{},
            projes:prj[0],
            /*showTra:false,*/
            /*showTray:false,*/
            proActive:1,
            isActive: false,
            isMob:false,
            actOne:true, 
            actTwo:false,
            traCP:false,
            simage:'',
            slate:configvar[1]
            
        }  
    },
    computed:{     
        mybase(){
           // return prj;
           return this.projes;
        },
        realPro(){
            //this.moveItt('left');
            //console.log("------");
            //console.log(prj);
             if (this.proActive==1) {  return prj[0]; }
            else{  return prj[1]; } 
        }
    },
    methods:{
        focusOnTable() {
        const ttt = this.$refs.refhidden;
        ttt.focus();
        },

    showMod(ima){
        this.simage=ima;
        document.getElementById('viewImage').style.display='block';
    },
     showAbout(){
        //this.simage=ima;
        document.getElementById('viewAbout').style.display='block';
    },
    googleTranslateElementInit(){
    this.traCP=!this.traCP;
    new google.translate.TranslateElement({pageLanguage: 'en'}, 'gootrael');
    },
    detectMob() {
            const toMatch = [
                /Android/i,
                /webOS/i,
                /iPhone/i,
                /iPad/i,
                /iPod/i,
                /BlackBerry/i,
                /Windows Phone/i
            ];
            
            return toMatch.some((toMatchItem) => {
                return navigator.userAgent.match(toMatchItem);
            });
        },
   
     showItt(item){
        this.itt=item;
        this.isActive=true;
       // console.log(this.itt);
    },
    moveItt(tmov){
      if (this.proActive==1) { this.projes=prj[0]; }
      else{ this.projes=prj[1]; } 
        let projs=this.projes;
        let signal=false;
        let idex=-1;
        let idMobRight=2;
        let idMobLeft=1;
        let idMobLeft2=1;
        if (this.isMob==true){
            idMobRight=1;
            idMobLeft=0;
            idMobLeft2=1;
        }
        this.actOne=false;
        projs.forEach(jobj => {
            Object.entries(jobj).forEach(([khey, vall]) => {
                if (khey=="idd"){ idex++; }
                if (tmov=="right"){
                if ((khey=="visible")&&(vall==true)&&(signal==false)){
                 if ((projs[idex].visible!=undefined)&&(projs[idex+idMobRight]!=undefined)){
                     projs[idex].visible=false;
                     projs[idex+idMobRight].visible=true;   
                     signal=true;
                 }
                 
                }
                }
                if (tmov=="left"){
                 if ((khey=="visible")&&(vall==true)&&(signal==false)){
                 if ((projs[idex+idMobLeft]!=undefined)&&(projs[idex-idMobLeft2]!=undefined)){ 
                     projs[idex-idMobLeft2].visible=true;
                      projs[idex+idMobLeft].visible=false;
                      signal=true;   
                      
                }
                }    
                }
                
         });
        });
        this.projes=projs;
        this.actOne=true;
        //console.log(this.mybase[0].name);
        //this.mybase;
    
    },
    changeOne(lg){
        
        if (lg=='en'){
            //this.showTra=!this.showTra;
            this.proActive=2;
            this.moveItt('left');
            this.slate=configvar[0];
             //console.log("En.......");
                
        }
         if (lg=='br'){
           // this.showTra=!this.showTra;
            this.proActive=1;
            this.moveItt('left');
            this.slate=configvar[1];
        }
       
    }

  },
  mounted(){

    if (this.detectMob()==false){
        this.isMob=false;
        
    } else{
        this.isMob=true;
        this.projes[1].visible=false;
        
    }
    this.showItt(this.projes[0]);
    //console.log(this.isMob);
  }

}

</script>

<style scoped>
    @import '@/assets/spacelab.css';
    #myImg {
        border-radius: 5px;
        cursor: pointer;
        transition: 0.3s;
    }
    #myImg:hover {opacity: 0.7;}
    .centerblock{
    
        width: 50%;
        border: 1px solid;
        margin: auto;
        /*display:table;*/
       
    }
    .pdd { 
        float:center;
        width:13.33333%
    }
    .retblock{
        height: 9rem; 
        width: 7rem;
        flex-direction: row;
        background-color:#0095df;
        color:#ebecee;
        /*display: inline-flex;table-cell
        position: absolute;
        */
       display: flex !important;
        align-items: center;
        justify-content: center;
        padding-right: 0rem;
        padding-left: 0rem;
            
    }
    .retbtn{
        background-color:#0095df;
        color:#ebecee;
    }
    .sideblock{
        height:5rem;
        width: 3rem;
        background-color:#0095df;
        color:#ebecee;
       font-size:30px;
      
    }
    .liner{
        display: inline;
    }
    .menublock{
        color:#ebecee;
        background-color:#5077A1;
         width: 20%;
        border: 1px solid;
        z-index:1055;
    }
/* - - - - - - - - - - - - - - - - - - - */
/* - - - - - - - - - - - - - - - - - - - */
/* - - - - - - - - - - - - - - - - - - - */
/* - - - - - - - - - - - - - - - - - - - */

    
    
    .centralize {
    margin: auto;
    width: 150%;
    border: 0px ;
    padding: 2px;
    }

  
</style>
