<template>


<span class="bg-dark p-1">
 <span v-if="isMob==true"><a class="dropbtnII" @click="showTra=!showTra">Translate </a>
            <div class="dropdownII-content" v-if="showTra">
            <a class="nav-link"  @click="emitFunc('br')" >English</a>
            <a class="nav-link" @click="googleTranslateElementInit()">Other languages</a>
            <a class="nav-link"  @click="emitFunc('en')">Show Original</a>
            </div>
  </span>   
        <span class="dropdownIII" v-show="isMob==false">
            <a class="dropbtnIII">Translate</a>
            <div class="dropdownIII-content">
            <a @click="emitFunc('br')">English</a>
            <a @click="googleTranslateElementInit()">Other languages</a>
            <a  @click="emitFunc('en')">Show Original</a>
            </div>
          
        </span> 
    <span> |
    <a class="bg-dark p-1 cursorp" @click="showCon=!showCon">{{conn}}</a>
    </span>
</span><br>
<span class="bg-dark p-1 text-white pl-2" v-if="showCon">
<small>adalberto.mao@outlook.com</small><br>
</span>
<!-- . . . . Google Translate element . . . . . . . .--> 
<!-- . . . . . . . . . . . . . . . . . . .  . . . .  -->
 <span v-if="traCP"><div id="gootrael"></div></span>
<!-- . . . . . . . . . . . . . . . . . . .  . . . .  --> 
<!-- . . . . . . . . . . . . . . . . . . .  . . . .  --> 

</template>
<script>
/* eslint-disable */
  export default {
  data(){
        return {
        showTra:false,
        traCP:false,
        showCon:false,
        conn:'Contact'
   }},     
   props: {
     isMob: Boolean,
     str1:String,
     str2:String
   }, 
   name: 'NavbarComp',
   components: {
    
  },
    
    methods:{
      emitFunc(vall){
        this.showTra=!this.showTra;
        this.conn='Contact';
        this.$emit('changeToOne',vall);
        
      },
      googleTranslateElementInit(){
        this.traCP=!this.traCP;
        new google.translate.TranslateElement({pageLanguage: 'en'}, 'gootrael');
    },
  },
  mounted(){
     
  }

}

</script>
<style scoped>
/* - - - - - - - - - - - - - - - - - - - */
/* - - - - - - - - - - - - - - - - - - - */
.cursorp{
  cursor: pointer;
}
.dropbtnII {
    border: none;
    cursor: pointer;
    text-decoration:none;
    color:#beeaf7;
}
.dropdownII {
  position: relative;
  display: inline-block;
  text-decoration:none;
}
/* - - - - - - - - - - - - - - - - - - - */
.dropbtnIII {
    border: none;
    cursor: pointer;
    text-decoration:none;
    color:#beeaf7;
}
.dropdownIII {
  position: relative;
  display: inline-block;
  text-decoration:none;
}


/* - - - - - - - - - - - - - - - - - - - */
.dropdownIII-content {
    cursor: pointer;
  display: none;
  position: absolute;
  z-index: 1;
}
.dropdownIII-content a {
  background-color:#EFEFEF;
  color:#064f9e;
  padding: 10px 13px;
  text-decoration: none;
  display: block;
}
.dropdownIII-content a:hover{
    background-color:#15f2e7;
    color:#064f9e;
}
/* - - - - - - - - - - - - - - - - - - - */
.dropdownII-content {
  display: inline;
  position: absolute;
  z-index: 1;
}
/* Links inside the dropdown */
.dropdownII-content a {
  background-color:#EFEFEF;
  color:#064f9e;
  padding: 10px 13px;
  text-decoration: none;
  display: block;
}


.dropdownII:hover .dropdownII-content {
  display: block;
}
.dropdownIII:hover .dropdownIII-content {
  display: block;
  
}

.dropdownII:hover .dropbtnII {
  
}
    

</style>
