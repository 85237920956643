<template>
<div>
<h6>{{slate.o}}</h6>
       
        <div class="progress">
        <div class="progress-bar progress-bar-striped bg-success" role="progressbar" style="width: 90%;" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100">{{slate.p}}</div>
        </div>
        
         <div class="progress">
        <div class="progress-bar progress-bar-striped" role="progressbar" style="width: 95%;" aria-valuenow="99" aria-valuemin="0" aria-valuemax="100">{{slate.q}}</div>
        </div>
        
          <div class="progress">
        <div class="progress-bar progress-bar-striped bg-success" role="progressbar" style="width: 80%;" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100">{{slate.v}}</div>
        </div>
        
         <div class="progress">
        <div class="progress-bar progress-bar-striped" role="progressbar" style="width: 70%;" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100">{{slate.r}}</div>
        </div>
        
         <div class="progress">
        <div class="progress-bar progress-bar-striped bg-success" role="progressbar" style="width: 60%;" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100">{{slate.s}}</div>
        </div>
        
         <div class="progress">
        <div class="progress-bar progress-bar-striped" role="progressbar" style="width: 51%;" aria-valuenow="51" aria-valuemin="0" aria-valuemax="100">{{slate.t}}</div>
        </div>
        
         <div class="progress">
        <div class="progress-bar progress-bar-striped bg-success" role="progressbar" style="width: 10%;" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100">{{slate.u}}</div>
        </div>
</div>

</template>

<script>
 /* eslint-disable */
    export default {
        name:'Aboutcomp',
        props:{
            slate:Object
        }
    }
</script>