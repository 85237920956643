<template>
<div class="container col-md-10">


  <div :class="aclass">
    <div class="col-4 col-sm-4" v-if="typeA=='about'" style="overflow: auto;">
        <img :src="colAO.c" style="max-height:40px; max-weight:50px; border-radius: 40px;"><br>
        <strong>{{colAO.a}}</strong>
        <p><small style="">{{ag}}:{{colAO.b}}</small></p> 
        
    </div>
     <div class="col-4 col-sm-4" v-if="typeA=='basic'" style="overflow: auto;"><strong>{{colA}}</strong></div>
    <div class="col-8 bleft text-black" v-if="typeB=='basic'" style="">{{colB}} </div>
    <div class="col-8 bleft text-black" v-if="typeB=='list'">
     &nbsp;<ul><li v-for="vvv in neoList" :key="vvv.khey">{{vvv.vall}}</li></ul>
    <hr></div>
    
  </div>



  
  </div>
</template>
 
<script>
/* eslint-disable */
//width:20%
  export default {
      data(){
        return {
            listt: [],
            aclass:"row align-items-center blotton"
           } },
            
   props: {
     
     typeA:String,
     typeB:String,
     colAO:Object,
     colBA:Array,
     colB:String,
     colA:String,
     ag:String,
     isMob:Boolean
   }, 
   name: 'TableressComp',
   
//methods:{ },
  computed:{
    neoList(){
        let neovar=[];
        let tmp={}; let ccc=-1;
        if (this.typeB=='list'){
            this.listt=this.colBA;
            this.listt.forEach(jjj=>{
                ccc++;
                tmp={"vall":jjj, "khey":ccc};
                neovar.push(tmp);
            });
            
        }
        return neovar;

    }
  },
  mounted(){
     if (this.isMob==true){
        this.aclass="grid align-items-center blotton";
     }
  }

}

</script>
<style scoped>
 .blotton{border-bottom:1px solid #c1c1c1}
 .bleft{border-left:1px solid #c1c1c1}
 .brit{border-right:1px solid #c1c1c1}
</style>
