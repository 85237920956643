<template>

 <!-- . . . . . . . . . . . . . . . . . . .  . . . .  --> 
 <!-- . . . . Navigation bar . . . . . . .   . . . .  -->
 <!-- . . . . . . . . . . . . . . . . . . .  . . . .  --> 
<NavbarComp :isMob="isMob" @changeToOne="changeOne" 
:str1="resdefault.h" :str2="resdefault.h"  />
<!-- . . . . . . . . . . . . . . . . . . .  . . . .  -->
<!-- . . . . . . . . . . . . . . . . . . .  . . . .  --> 
<!-- . . . . . . . . . . . . . . . . . . .  . . . .  --> 


    <div class="container-fluid  mt-4">  
    <div class="container bg-white col-md-12 ">
<!-- . . . . . . . . . . . . . . . . . . .  . . . .  -->
<!-- . About you(Age), 2 columns - Photo + description  .  -->
<!-- . . . . . . . . . . . . . . . . . . .  . . . .  --> 
<br>
<TableressComp typeA="about" typeB="basic" :colAO="resfirst" 
   :colB="resdefault.ab" :ag="resdefault.i" /> <br>


<!-- . . . . . . . . . . . . . . . . . . .  . . . .  --> 
<!-- Experiência - 2 columns -   --> 
<!-- . . . . . . . . . . . . . . . . . . .  . . . .  --> 
<TableressComp typeA="basic" typeB="basic" :colA="resdefault.b"
 :colB="resdefault.ex" /> <br>



<!-- . . . . . . . . . . . . . . . . . . .  . . . .  --> 
<!-- Conhecimentos -  --> 
<!-- . . . . . . . . . . . . . . . . . . .  . . . .  --> 
<TableressComp typeA="basic" typeB="list" :colA="resdefault.c"
 :colBA="resdefault.kn" /> <br>


<!-- . . . . . . . . . . . . . . . . . . .  . . . .  --> 
<!-- Cursos -  --> 
<!-- . . . . . . . . . . . . . . . . . . .  . . . .  --> 
<TableressComp typeA="basic" typeB="list" :colA="resdefault.d"
 :colBA="resdefault.co" />  <br>


<!-- . . . . . . . . . . . . . . . . . . .  . . . .  --> 
<!-- Formação -  --> 
<!-- . . . . . . . . . . . . . . . . . . .  . . . .  --> 
<TableressComp typeA="basic" typeB="basic" :colA="resdefault.e"
 :colB="resdefault.fo" />   <br>


<!-- . . . . . . . . . . . . . . . . . . .  . . . .  --> 
<!-- Idiomas - Inglês Avançado (Leitura)  --> 
<!-- . . . . . . . . . . . . . . . . . . .  . . . .  --> 
<TableressComp typeA="basic" typeB="basic" :colA="resdefault.f"
 :colB="resdefault.ii" /> <br>
    </div>
    </div>
<!-- . . . . . . . . . . . . . . . . . . .  . . . .  --> 
<!-- . . . . . . . . . . . . . . . . . . .  . . . .  -->  
<br>
<br>
<FooterComp />

</template>

<script>
 /* eslint-disable */
import resdata from '@/jsons/resume.json';

import TableressComp from '@/components/Tableress.vue';
import NavbarComp from '@/components/Navbar.vue';
import FooterComp from '@/components/Footer.vue';


export default {
   name: 'ResumeComp',
       data(){
          return {
              resdefault:resdata[1],
              resfirst:resdata[0],
              isMob:false
      }},
    components: { TableressComp,NavbarComp,FooterComp },
    methods:{
      
      changeOne(lg){
        
        if (lg=='en'){
            
            this.resdefault=resdata[2];      
        }
         if (lg=='br'){
            //this.showTra=!this.showTra;
            this.resdefault=resdata[1];
        }
       
    },
     detectMob() {
       const toMatch = [ /Android/i, /webOS/i, /iPhone/i,
            /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i
            ];
            return toMatch.some((toMatchItem) => {
                return navigator.userAgent.match(toMatchItem);
            });
        },
    },
    mounted(){
      if (this.detectMob()==false){
        this.isMob=false;
        
      } else{
        this.isMob=true;
        //this.projes[1].visible=false;
        
      }
      
    }   
  }
</script>

<style scoped>
    @import '@/assets/spacelab.css';
    
</style>    
