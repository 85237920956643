import { createRouter, createWebHashHistory } from 'vue-router'
//import HomeView from '../views/HomeView.vue'
import FolioComp from '../views/Folio.vue'
import ResumeComp from '../views/Resume.vue'

const routes = [
  {
    path: '/',
    name: 'resume',
    component: ResumeComp
  },
   {
    path: '/folio',
    name: 'folio',
    component: FolioComp
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting 
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
