<template>
<div>
<p class="bg-dark p-1">
    <section class="p-4">
      <!-- Footer -->
      <footer class="bg-dark text-center text-white">
        <!-- Grid container -->
        <div class="container p-4">
          <!-- Section: Social media -->
          <section class="mb-4">
          
<a href="https://pt.gravatar.com/" target="_blank" role="button">
<span class="badge gtar fdone m-1">&nbsp;</span></a>

<a href="https://github.com/Xaobin" target="_blank" role="button">
<span class="badge ghub fdone m-1">&nbsp;</span></a>

<a href="https://www.linkedin.com" target="_blank" role="button">
<span class="badge ldin fdone m-1">&nbsp;</span></a>

            
            <!-- a class="btn btn-outline-light btn-floating m-1" href="#!" role="button"
              ><i class="fab fa-instagram"></i
            ></a>

         
            <a class="btn btn-outline-light btn-floating m-1" href="#!" role="button"
              ><i class="fab fa-linkedin-in"></i
            ></a>

           
            <a class="btn btn-outline-light btn-floating m-1" href="#!" role="button"
              ><i class="fab fa-github"></i
            ></a -->
          </section>
          

          

          <!-- Section: Text -->
          <section class="mb-4">
            <p>
             Paulatim deambulando, longum conficitur ite.
            </p>
            <input type="hidden" id="Yahweh" value="TWV1IERldXMgw6kgWUFIIChFbGlhaHUpLCBzdWEgdml0w7NyaWEgKE5ldHphY2gpIGF0aXZhIG8gdmXDrWN1bG8gZGUgYXNjZW7Dp8OjbyAoTWVya2FiYWgp">
          </section>
          <!-- Section: Text -->

          <!-- Section: Links -->
          <section class="">
            <!--Grid row-->
            <div class="row">
              <!--Grid column-->
              <div class="col-lg-3 col-md-6 mb-4 mb-md-0">
                <h5 target="_blank" class="text-uppercase">Front End</h5>

                <ul class="list-unstyled mb-0">
                  <li>
                    <a href="https://php.net" target="_blank" class="text-white">php.net</a>
                  </li>
                  <li>
                    <a href="https://laravel.com/docs/10.x" target="_blank" class="text-white">Laravel</a>
                  </li>
                  <li>
                    <a href="https://codeigniter.com" target="_blank" class="text-white">CodeIgniter</a>
                  </li>
                  <li>
                    <a href="https://laravel-excel.com" target="_blank" class="text-white">Laravel Excel</a>
                  </li>
                </ul>
              </div>
              <!--Grid column-->

              <!--Grid column-->
              <div class="col-lg-3 col-md-6 mb-4 mb-md-0">
                <h5 target="_blank" class="text-uppercase">Back End</h5>

                <ul class="list-unstyled mb-0">
                  <li>
                    <a href="https://vuejs.org" target="_blank" class="text-white">Vuejs</a>
                  </li>
                  <li>
                    <a href="https://react.dev" target="_blank" class="text-white">React</a>
                  </li>
                  <li>
                    <a href="https://npmjs.com" target="_blank" class="text-white">npm software</a>
                  </li>
                  <li>
                    <a href="https://www.typescriptlang.org/" target="_blank" class="text-white">Typescript</a>
                  </li>
                </ul>
              </div>
              <!--Grid column-->

              <!--Grid column-->
              <div class="col-lg-3 col-md-6 mb-4 mb-md-0">
                <h5 target="_blank" class="text-uppercase">Links</h5>

                <ul class="list-unstyled mb-0">
                  <li>
                    <a href="https://github.com/" target="_blank" class="text-white">Github</a>
                  </li>
                  <li>
                    <a href="https://getbootstrap.com/" target="_blank" class="text-white">Bootstrap</a>
                  </li>
                  <li>
                    <a href="https://vscodium.com/" target="_blank" class="text-white">VSCodium</a>
                  </li>
                  <li>
                    <a href="https://bitnami.com/" target="_blank" class="text-white">Bitnami</a>
                  </li>
                </ul>
              </div>
              <!--Grid column-->

              <!--Grid column-->
              <div class="col-lg-3 col-md-6 mb-4 mb-md-0">
                <h5 target="_blank" class="text-uppercase">Links</h5>

                <ul class="list-unstyled mb-0">
                  <li>
                    <a href="https://www.convergenciadigital.com.br/" target="_blank" class="text-white">Convergência digital</a>
                  </li>
                  <li>
                    <a href="https://openai.com/blog/chatgpt" target="_blank" class="text-white">ChatGPT</a>
                  </li>
                  <li>
                    <a href="https://distrowatch.com/?language=PT" target="_blank" class="text-white">Distro watch</a>
                  </li>
                  <li>
                    <a href="https://bard.google.com/" target="_blank" class="text-white">Google Bard</a>
                  </li>
                </ul>
              </div>
              <!--Grid column-->
            </div>
            <!--Grid row-->
          </section>
          <!-- Section: Links -->
        </div>
        <!-- Grid container -->

        <!-- Copyright -->
        <div target="_blank" class="text-center p-3" style="background-color: rgba(0, 0, 0, 0.2);">
          © 2023 
        </div>
        <!-- Copyright -->
      </footer>
      <!-- Footer -->
    </section>

</p>
</div>
</template>
<script>
/* eslint-disable */
  export default {
    name:'FooterComp'

}

</script>
<style scoped>
.fdone{
background-color:#333333;
height:24px;
width:24px;
   background-repeat: no-repeat;

  background-size: contain;
}
.gtar{
background-image: url('@/assets/gtar1.png'); 
}
.ldin{
background-image: url('@/assets/ldin1.png'); 
}
.ghub{
background-image: url('@/assets/ghub1.webp'); 
}
</style>
