<template>
 <div>
  <span class="bg-dark dropbtnII navbar-brand p-1">
    <router-link to="/">Resume</router-link> |
     <router-link to="/folio">Portfolio</router-link> |
  </span>
 
  <router-view/>
  
 </div>
</template>
<script>
/* eslint-disable */

     
</script>


<style>
 @import '@/assets/spacelab.css';


nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
/* - - - - - - - - - - - - - - - - - - - */
/* - - - - - - - - - - - - - - - - - - - */
/* - - - - - - - - - - - - - - - - - - - */
/* - - - - - - - - - - - - - - - - - - - */
a{
  color:fff;
}
a:hover {
  color: beeaf7;
}
.dropbtnII {
    border: none;
    cursor: pointer;
    text-decoration:none;
    color:#beeaf7;
}
</style>
